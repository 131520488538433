<template>
  <div class="RaUEbannerBox">
    <div class="con">
      <div class="goodsClass">
        <div class="allClass">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 544 545.3"
            xml:space="preserve"
            class="svg"
          >
            <g>
              <path
                d="M218.3,245.9H58.6C43.9,245.9,32,234,32,219.3V59.7C32,45,43.9,33.1,58.6,33.1h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C244.9,234,232.9,245.9,218.3,245.9z M58.6,59.7v159.6h159.6V59.7L58.6,59.7z M58.6,46.4l0,13.3h0V46.4z"
              ></path>
            </g>
            <g>
              <path
                d="M536.2,158.3L423.3,271.2c-10.4,10.4-27.3,10.4-37.6,0L272.8,158.3c-10.4-10.4-10.4-27.2,0-37.6L385.7,7.8  c10.4-10.4,27.3-10.4,37.6,0l112.9,112.9C546.6,131,546.6,147.9,536.2,158.3L536.2,158.3z M291.6,139.5l112.9,112.9l112.9-112.9  L404.5,26.6L291.6,139.5z M282.2,130.1l9.4,9.4l0,0L282.2,130.1z"
              ></path>
            </g>
            <g>
              <path
                d="M484.3,512H324.7c-14.7,0-26.6-11.9-26.6-26.6V325.7c0-14.7,11.9-26.6,26.6-26.6h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C510.9,500.1,499,512,484.3,512z M324.7,325.7v159.6h159.6V325.7L324.7,325.7z M324.7,312.4l0,13.3h0V312.4z"
              ></path>
            </g>
            <g>
              <path
                d="M218.3,512H58.6C43.9,512,32,500.1,32,485.4V325.7c0-14.7,11.9-26.6,26.6-26.6h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C244.9,500.1,232.9,512,218.3,512z M58.6,325.7v159.6h159.6V325.7L58.6,325.7z M58.6,312.4l0,13.3h0V312.4z"
              ></path>
            </g>
          </svg>
          <span>{{ $fanyi("特色类别") }}</span>
        </div>
        <div class="classMainBox">
          <div
            class="goodsClassMain"
            v-for="(classItem, classIndex) in lists"
            :key="'class' + classIndex"
            v-show="classIndex < 9"
          >
            <div
              class="goodsCl"
              @click="
                $fun.routerToPage(
                  '/CommoditySearch?keyword=' +
                    classItem.translation_name +
                    '&CN=' +
                    $fun.toCode(classItem.chinese_name)
                )
              "
            >
              <div class="classImg">
                <img :src="classItem.default_icon" alt="" />
                <img :src="classItem.trigger_icon" alt="" />
              </div>
              <div class="classSpan">
                <span>{{
                  !$store.state.showChinese
                    ? classItem.translation_name
                    : classItem.chinese_name
                }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="512"
                  viewBox="0 0 24 24"
                  width="512"
                >
                  <g id="_17" data-name="17">
                    <path
                      d="m15 19a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1 -.7 1.71z"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>

            <div class="classChildBox">
              <div class="classChild">
                <div>
                  <div class="className">
                    {{
                      !$store.state.showChinese
                        ? classItem.translation_name
                        : classItem.chinese_name
                    }}
                  </div>
                  <a
                    v-for="(childsItem, childsIndex) in classItem.childs"
                    :key="childsIndex"
                    href="#"
                    @click="
                      $fun.routerToPage(
                        '/CommoditySearch?keyword=' +
                          childsItem.translation_name +
                          '&CN=' +
                          $fun.toCode(childsItem.chinese_name)
                      )
                    "
                    >{{
                      !$store.state.showChinese
                        ? childsItem.translation_name
                        : childsItem.chinese_name
                    }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 多出的部分 -->
          <!-- <div
            class="goodsClassMain"
            v-for="(classItem, classIndex) in lists"
            :key="classIndex"
            v-show="classIndex >= 9 && showMore"
          >
            <div
              class="goodsCl"
              @click="
                $fun.routerToPage(
                  '/CommoditySearch?keyword=' +
                    classItem.translation_name +
                    '&CN=' +
                    $fun.toCode(classItem.chinese_name)
                )
              "
            >
              <div class="classImg">
                <img :src="classItem.default_icon" alt="" />
                <img :src="classItem.trigger_icon" alt="" />
              </div>
              <div class="classSpan">
                <span>{{
                  !$store.state.showChinese
                    ? classItem.translation_name
                    : classItem.chinese_name
                }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="512"
                  viewBox="0 0 24 24"
                  width="512"
                >
                  <g id="_17" data-name="17">
                    <path
                      d="m15 19a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1 -.7 1.71z"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>

            <div class="classChildBox">
              <div class="shadowBlock">
                <div class="classChild">
                  <div>
                    <div class="className">
                      {{
                        !$store.state.showChinese
                          ? classItem.translation_name
                          : classItem.chinese_name
                      }}
                    </div>
                    <a
                      v-for="(childsItem, childsIndex) in classItem.childs"
                      :key="childsIndex"
                      href="#"
                      @click="
                        $fun.routerToPage(
                          '/CommoditySearch?keyword=' +
                            childsItem.translation_name +
                            '&CN=' +
                            $fun.toCode(childsItem.chinese_name)
                        )
                      "
                      >{{
                        !$store.state.showChinese
                          ? childsItem.translation_name
                          : childsItem.chinese_name
                      }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="goodsClassMain" @click="showClassMore">
            <div class="goodsCl">
              <div class="classImg">
                <svg
                  t="1659417415711"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3288"
                  width="24"
                  height="24"
                  v-if="!showMore"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <defs></defs>
                  <path
                    d="M828.704099 196.575729C744.096116 112.384034 631.648434 66.016073 512 66.016073s-232.1288 46.367961-316.736783 130.559656C110.624271 280.800108 64 392.831501 64 512c0 119.199462 46.624271 231.199892 131.232254 315.424271 84.607983 84.191695 197.088348 130.559656 316.736783 130.559656s232.1288-46.367961 316.704099-130.559656c84.67163-84.255342 131.295901-196.288456 131.263217-315.455235C959.967316 392.800538 913.375729 280.800108 828.704099 196.575729zM736.00086 544.00086 544.00086 544.00086l0 192c0 17.695686-14.336138 32.00086-32.00086 32.00086s-32.00086-14.303454-32.00086-32.00086L479.99914 544.00086 288.00086 544.00086c-17.664722 0-32.00086-14.336138-32.00086-32.00086s14.336138-32.00086 32.00086-32.00086l192 0L480.00086 288.00086c0-17.664722 14.336138-32.00086 32.00086-32.00086s32.00086 14.336138 32.00086 32.00086l0 192 192 0c17.695686 0 32.00086 14.336138 32.00086 32.00086S753.696546 544.00086 736.00086 544.00086z"
                    p-id="3289"
                    fill="#1C2899"
                  ></path>
                </svg>

                <svg
                  v-else
                  height="512"
                  viewBox="0 0 24 24"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Layer_42" data-name="Layer 42">
                    <path
                      d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm5 11h-10a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2z"
                    />
                  </g>
                </svg>
              </div>
              <div class="classSpan">
                <span>{{ $fanyi("查看更多类别") }}</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="banner">
        <el-carousel
          trigger="click"
          height="478px"
          :interval="interval"
          arrow="never"
        >
          <el-carousel-item
            v-for="(item, index) in bannerList"
            :key="index"
            v-show="item.status"
          >
            <!-- 假如图片有指定连接才可以点击跳转 -->
            <a :href="item.href_url" v-if="item.href_url">
              <img :src="item.img_url" alt="" class="bannerImg" />
            </a>
            <img :src="item.img_url" alt="" class="bannerImg" v-else />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerList: [],
      interval: 5000,
      lists: [],
      showMore: false, //是否显示11个分类以外的分类
    };
  },
  components: {},
  computed: {},
  created() {
    this.getShopBanner();
    this.getData();
  },

  methods: {
    // 显示更多分类
    showClassMore() {
      this.showMore = !this.showMore;
    },
    loginOut() {
      localStorage.removeItem("backgroundOperationID");
      sessionStorage.removeItem("backendPersonnel");
      localStorage.removeItem("user_token");
      sessionStorage.removeItem("user_id");

      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.routerToPage("/");
      }
    },
    getShopBanner() {
      this.$api.getShopBanner().then((res) => {
        this.interval = res.data.switch_time * 1000;
        this.bannerList = res.data.list;
      });
    },
    // 获取搜索框分类数据和首页分类数据
    getData() {
      this.$api
        .getShopGroup(this.form)
        .then((result) => {
          this.changeData(result.data);
          this.lists = result.data;
          this.$store.commit("getgoodsList", result.data);
        })
        .catch((err) => {});
    },
    // 子分类排序
    changeData(data) {
      //console.log("分类数据", data);
      data.forEach((data1Item) => {
        data1Item.childs.sort(duiBi);
      });
      function duiBi(a, b) {
        // time 是时间的字段 a-b 正序 b-a 倒序
        return Date.parse(b.sort) - Date.parse(a.sort);
      }

      //console.log("排序后", data);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../css/mixin";
.RaUEbannerBox {
  margin-bottom: 30px;
  .con {
    margin: 0 auto;
    width: $pageWidth;
    display: flex;
    .goodsClass {
      width: 293.66px;
      margin-right: 20px;
      position: relative;

      .allClass {
        border-radius: 6px 6px 0 0;
        height: 49.58px;
        background-color: #1a289d;
        padding: 0px 36px 1px 28px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .svg {
          width: 20px;
          margin-right: 15px;
          fill: white !important;
          color: white;
        }
        span {
          color: white;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .classMainBox {
        background-color: white;
        padding: 10px 0px 0px 0px;
        min-height: 431px;
        border: #e8e8e8 solid 1px;
        border-radius: 0 0 6px 6px;
        display: flex;
        flex-direction: column;

        .goodsClassMain {
          height: 45px;
          display: flex;
          cursor: pointer;
          background-color: white;

          &:hover {
            .goodsCl {
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
              position: relative;
              z-index: 2;
              background-color: white;
              position: relative;
              &:before {
                content: "";
                background-color: white;
                position: absolute;
                right: -15px;
                width: 20px;
                height: 100%;
                z-index: 115;
                opacity: 1;
                top: 0;
              }
            }
            .classChild {
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
              border: 1px solid #e8e8e8;
              border-radius: 6px;
              position: relative;
              // border: solid;
              left: 2px;
              z-index: 9;
            }
          }

          .goodsCl {
            width: 100%;
            display: flex;
            padding-left: 20px;
            padding-right: 25px;

            .classImg {
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                color: white;
              }
              svg {
                width: 22px;
                height: 22px;
                margin-right: 15px;
              }
              img {
                width: 22px;
                height: 22px;
                margin-right: 15px;
                &:last-child {
                  display: none;
                }
              }
            }
            .classSpan {
              display: flex;
              align-items: center;
              width: 100%;
              span {
                font-size: 13px;
                font-weight: 500;
                line-height: 1.9em;
              }
              svg {
                transform: rotate(180deg);
                width: 14px;
                height: 26px;
                margin-left: auto;
              }
            }
          }

          .classChildBox {
            position: absolute;
            left: 100%;
            width: 255px;
            display: none;
            z-index: 3;
            top: 50px;
            overflow: hidden;
            height: 100%;
            padding-bottom: 50px;
            .shadowBlock {
              // border: solid;
              height: 100%;
            }
            .classChild {
              > div {
                max-height: 100%;
              }
              padding: 0 0px 20px 20px;
              margin-left: -20px;
              width: 235px;
              height: 100%;
              overflow-y: auto;
              &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
              }
              &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #c0c0c0;
              }
              &::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
              }
              display: flex;
              flex-wrap: wrap;
              background-color: white;

              .className {
                font-weight: 600;
                font-size: 14px;
                position: sticky;
                top: 0px;
                padding-top: 20px;
                padding-left: 30px;
                padding-bottom: 10px;
                color: #1a289d;
                background-color: white;
                width: 100%;
              }
              a {
                width: 210px;
                height: 34px;
                border-radius: 5px;
                text-overflow: -o-ellipsis-lastline;
                line-height: 14px;
                overflow: hidden;
                display: flex;
                padding: 2px 0px;
                align-items: center;
                font-size: 13px;
                font-weight: 500;
                padding-left: 30px;
                transition: 0.3s;
                &:hover {
                  background-color: #f8f9fd;
                }
              }
            }
          }
          &:hover {
            .classImg {
              img {
                width: 22px;
                height: 22px;
                margin-right: 15px;
                display: none;
                &:last-child {
                  display: block;
                }
              }
            }
            .classSpan {
              span {
                color: #1e2997;
              }
            }
            .classChildBox {
              display: block;
            }
          }
        }
      }
    }
    .banner {
      flex: 1;
      .bannerImg {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      // margin: 20px 30px 0 20px;
      // 轮播图两侧样式
      /deep/.el-carousel__arrow--right,
      /deep/.el-carousel__arrow--left {
        width: 41px;
        height: 41px;
        background: #90909f !important;
        opacity: 0.75;
        border-radius: 50%;
        &:hover {
          opacity: 1;
        }
      }
      /deep/ .el-carousel__arrow {
        background: rgba(255, 255, 255, 0.5);
        text-align: center;
        i {
          color: white;
          font-size: 23px;
          font-weight: 700;
        }
      }
      /deep/.el-carousel__button {
        width: 12px;
        height: 12px;
        background: #ffffff;
        border-radius: 50%;
      }
      /deep/.el-carousel__indicator.el-carousel__indicator--horizontal.is-active
        .el-carousel__button {
        outline: 3px solid $homePageSubjectColor;
        background-color: transparent;
      }
    }
  }
}
</style>
