<template>
  <div>
    <el-dialog
      class="youTuBeDiaCss"
      title="提示"
      :visible.sync="dialogVisible"
      width="865px"
      :before-close="handleClose"
    >
      <div class="videoBox">
        <div class="closeBtn" @click="handleClose">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66cfcb2c66bf2.svg"
            alt=""
          />
        </div>
        <div class="borraBox">
          <video autoplay controls controlsList="nodownload">
            <source
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66cef237d6ded.mp4"
              type="video/mp4"
            />

            您的浏览器不支持 video 标签。
          </video>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  components: {},
  computed: {},
  created() {
    if (sessionStorage.getItem("beginnerTips")) {
      this.dialogVisible = true;
    }
  },
  methods: {
    handleClose() {
      sessionStorage.removeItem("beginnerTips");
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.videoBox {
  position: relative;
  border-radius: 8px;
  background-color: transparent;
  padding: 0;

  .closeBtn {
    position: absolute;
    right: -57.222px;
    top: -50px;
    background-color: #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    @extend .dip;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .borraBox {
    border-radius: 8px;
    height: 479px;
    overflow: hidden;
    video {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.youTuBeDiaCss {
  .el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      background-color: transparent;
      padding: 0;
    }
  }
}
</style>
