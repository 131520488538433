<template>
  <div id="homePageCon">
    <HomePageTop class="HomePageTopBox"></HomePageTop>
    <SlideShow></SlideShow>
    <!-- <ourAdvantages></ourAdvantages> -->
    <recommendCommodities></recommendCommodities>
    <ClassifyGoods />
    <InformBounced></InformBounced>
    <zhuanzhuVue></zhuanzhuVue>
    <beginnerTipsDia ref="BeginnerTipsDia" />
    <Foot />
  </div>
</template>

<script>
import beginnerTipsDia from "./components/beginnerTipsDia.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";
import ClassificationOfGoods from "../../components/homePage/ClassificationOfGoods.vue";
import SlideShow from "../../components/homePage/SlideShow.vue";
import OperatingOptions from "../../components/homePage/OperatingOptions.vue";
import recommendCommodities from "../../components/homePage/recommendCommodities.vue";
import zhuanzhuVue from "../../components/homePage/zhuanzhu.vue";
// import ourAdvantages from "../../components/homePage/ourAdvantages";
import Foot from "../../components/foot/Foot.vue";
import InformBounced from "../../views/order/InformBounced";
import ClassifyGoods from "../../components/homePage/classifyGoods.vue";
export default {
  data() {
    return {};
  },
  components: {
    HomePageTop,
    ClassificationOfGoods,
    SlideShow,
    OperatingOptions,
    recommendCommodities,
    // ourAdvantages,
    Foot,
    InformBounced,
    ClassifyGoods,
    zhuanzhuVue,
    beginnerTipsDia,
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
