var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"classifyGoods"},_vm._l((_vm.goodsList),function(item,index){return _c('div',{key:index,staticClass:"classify"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"title",style:({ background: item.default_back_color })},[_c('h3',{on:{"click":function($event){_vm.$fun.routerToPage(
              '/CommoditySearch?keyword=' +
                item.translation_name +
                '&CN=' +
                _vm.$fun.toCode(item.chinese_name)
            )}}},[_vm._v(" "+_vm._s(item.translation_name)+" ")])]),_c('div',{staticClass:"menus"},_vm._l((item.childs),function(menu,menus){return _c('div',{key:menus,staticClass:"menu",class:{ checked: item.index == menus },on:{"mouseover":function($event){return _vm.getResources(menu, index, menus)},"click":function($event){_vm.$fun.routerToPage(
              '/CommoditySearch?keyword=' +
                menu.translation_name +
                '&CN=' +
                _vm.$fun.toCode(menu.chinese_name)
            )}}},[_c('div',{staticClass:"menus-name",style:({
              background:
                item.index == menus ? item.trigger_back_color : 'transparent',
              color: item.index == menus ? item.default_back_color : '',
            })},[_vm._v(" "+_vm._s(menu.translation_name)+" ")]),_c('div',{staticClass:"border",style:({
              background:
                item.index == menus ? item.default_back_color : 'transparent',
            })})])}),0)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg"},[_c('el-image',{attrs:{"src":item.web_img},on:{"click":function($event){_vm.$fun.routerToPage(
              '/CommoditySearch?keyword=' +
                item.translation_name +
                '&CN=' +
                _vm.$fun.toCode(item.chinese_name)
            )}}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})]),_c('div',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_c('i',{staticClass:"el-icon-loading"})])])],1),_c('div',{staticClass:"SearchConterCrosswise_public"},_vm._l((item.resources),function(goods,goodsIndex){return _c('router-link',{key:goodsIndex,staticClass:"item",attrs:{"to":'/ProductDetails?goods_id=' + goods.goods_id,"target":"_blank"},on:{"click":function($event){return _vm.$fun.toCommodityDetails(goods.goods_id)}}},[_c('div',{staticClass:"SearchConterCrosswiseCon"},[_c('div',{staticClass:"SearchConterCrosswiseImg"},[_c('el-image',{staticClass:"goodsImg",attrs:{"src":goods.imgUrl}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})]),_c('div',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_c('i',{staticClass:"el-icon-loading"})])])],1),_c('div',{staticClass:"rests"},[_c('p',{staticClass:"goodsTitle",attrs:{"title":goods.title}},[_vm._v(_vm._s(goods.title))]),_c('p',{staticClass:"price"},[_c('span',[_vm._v(" "+_vm._s(_vm.$fun.EURNumSegmentation( _vm.$fun.ceil(goods.goodsPrice * _vm.exchangeRate) ))+"€")]),_vm._v(" "+_vm._s(_vm.$fun.RMBNumSegmentation(goods.goodsPrice))+"￥ ")])])])])}),1)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }