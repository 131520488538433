import { render, staticRenderFns } from "./beginnerTipsDia.vue?vue&type=template&id=cd7f48b2&scoped=true&"
import script from "./beginnerTipsDia.vue?vue&type=script&lang=js&"
export * from "./beginnerTipsDia.vue?vue&type=script&lang=js&"
import style0 from "./beginnerTipsDia.vue?vue&type=style&index=0&id=cd7f48b2&lang=scss&scoped=scoped&"
import style1 from "./beginnerTipsDia.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd7f48b2",
  null
  
)

export default component.exports