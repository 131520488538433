<template>
  <div id="OperatingOptions">
    <div class="list base-w">
      <router-link to="/quienes-somos" class="item">
        <span> RAKUMART </span>
      </router-link>
      <div class="item">
        <span>{{ $fanyi("用户指南") }} </span>
      </div>
      <div class="item">
        <span>{{ $fanyi("常见问题解答") }}</span>
      </div>
      <!-- <router-link to="/register"  class="item">
        <span> 免费注册 </span>
      </router-link> -->
      <router-link to="/comisiones-rakumart" class="item">
        <span> {{ $fanyi("手续费") }} </span>
      </router-link>
      <div class="item">
        <span>{{ $fanyi("国际运费") }}</span>
      </div>
      <router-link to="/servicios-adicionales" class="item">
        <span>{{ $fanyi("option说明界面") }}</span>
      </router-link>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},

  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
#OperatingOptions {
  @import "../../css/mixin";
  width: 100%;
  margin-bottom: 100px;

  // 组件内容样式
  .list {
    background-color: #fff;
    height: 100px;
    border-radius: 6px;
    display: flex;
    overflow: hidden;
    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: $homePageSubjectColor;
      }
      &:hover a {
        color: #fff;
        background-color: $homePageSubjectColor;
      }
    }
  }
}
</style>
