<template>
  <div>
    <!-- 用户未读通知显示弹窗 -->
    <Dialog ref="dialog" :config="config">
      <el-carousel trigger="click" :autoplay="false" :indicator-position="'none'" @change="beenRead"
        :height="boxHeight + 'px'" :arrow="informList.length > 1 ? 'always' : 'never'">
        <el-carousel-item v-for="(item, index) in informList" :key="index">
          <div class="InformBounced ql-editor">
            <h1>{{ item.title }}</h1>
            <p class="timeBox">{{ item.created_at }}</p>
            <div class="con" v-html="item.content" v-if="item.is_email_notice"></div>
            <InFormDetailEmail v-else></InFormDetailEmail>
          </div>
        </el-carousel-item>
      </el-carousel>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "../../components/public/Dialog";
import InFormDetailEmail from '../UserCenter//views/inform/InFormDetailEmail.vue';
export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "1200px",
        title: "",
        btnTxt: [this.$fanyi("取消")],
      },
      informList: [],
      boxHeight: window.innerHeight > 500 ? window.innerHeight - 358 : 800,//自适应高度
    };
  },
  components: { Dialog, InFormDetailEmail },
  computed: {},
  mounted() {
    window.onresize = () => {//自适应高度
      this.boxHeight =
        window.innerHeight > 500 ? window.innerHeight - 358 : 800;
    };

    this.$api.noticeUnreadNotice().then((res) => {
      if (res.code != 0) return;
      this.informList = res.data;
      if (res.data.length > 0) {
        this.$refs.dialog.open(
          "",
          () => { },
          () => { }
        );
        this.beenRead(0);
      }
    });
  },
  methods: {
    // 设置已读
    beenRead(to) {
      this.$api
        .noticeReadNotice({ id: this.informList[Number(to)].id })
        .then((res) => { });
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../css/mixin.scss";

/deep/.el-carousel__item {
  background-color: #fafafa;
  overflow: auto;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;

    &:hover {
      width: 10px !important;
    }
  }
}

.InformBounced {
  background: #fafafa;
  margin: 0 auto;
  border-radius: 10px;
  width: 1120px;
  min-height: 380px;
  padding: 20px 40px 40px;
  background-color: #fafafa;
  overflow: visible;

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;

    margin-bottom: 10px;
    text-align: center;
  }

  .timeBox {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .con {
    line-height: 20px;
    font-size: 14px;
  }
}

/deep/.el-dialog {
  margin-top: 10vh !important;
}

/deep/.el-dialog__body {
  padding: 0 30px !important;
}
</style>