<template>
  <div class="classifyGoods">
    <div class="classify" v-for="(item, index) in goodsList" :key="index">
      <!-- 头部 -->
      <div class="head">
        <!-- 分类名 -->
        <div class="title" :style="{ background: item.default_back_color }">
          <h3
            @click="
              $fun.routerToPage(
                '/CommoditySearch?keyword=' +
                  item.translation_name +
                  '&CN=' +
                  $fun.toCode(item.chinese_name)
              )
            "
          >
            {{ item.translation_name }}
          </h3>
        </div>
        <!-- 子分类菜单 -->
        <div class="menus">
          <div
            class="menu"
            v-for="(menu, menus) in item.childs"
            :key="menus"
            :class="{ checked: item.index == menus }"
            @mouseover="getResources(menu, index, menus)"
            @click="
              $fun.routerToPage(
                '/CommoditySearch?keyword=' +
                  menu.translation_name +
                  '&CN=' +
                  $fun.toCode(menu.chinese_name)
              )
            "
          >
            <div
              class="menus-name"
              :style="{
                background:
                  item.index == menus ? item.trigger_back_color : 'transparent',
                color: item.index == menus ? item.default_back_color : '',
              }"
            >
              {{ menu.translation_name }}
            </div>
            <div
              class="border"
              :style="{
                background:
                  item.index == menus ? item.default_back_color : 'transparent',
              }"
            ></div>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <!-- 左边背景 -->
        <div class="bg">
          <el-image
            :src="item.web_img"
            @click="
              $fun.routerToPage(
                '/CommoditySearch?keyword=' +
                  item.translation_name +
                  '&CN=' +
                  $fun.toCode(item.chinese_name)
              )
            "
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
        </div>

        <!-- 右边商品组 -->
        <div class="SearchConterCrosswise_public">
          <router-link
            :to="'/ProductDetails?goods_id=' + goods.goods_id"
            class="item"
            v-for="(goods, goodsIndex) in item.resources"
            :key="goodsIndex"
            @click="$fun.toCommodityDetails(goods.goods_id)"
            target="_blank"
          >
            <div class="SearchConterCrosswiseCon">
              <!-- 图片 -->
              <div class="SearchConterCrosswiseImg">
                <el-image :src="goods.imgUrl" class="goodsImg">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
                <!-- <img :src="goods.imgUrl" alt="" /> -->
              </div>
              <div class="rests">
                <!-- 商品简介 -->
                <p class="goodsTitle" :title="goods.title">{{ goods.title }}</p>
                <!-- 价格 -->
                <p class="price">
                  <span>
                    {{
                      $fun.EURNumSegmentation(
                        $fun.ceil(goods.goodsPrice * exchangeRate)
                      )
                    }}€</span
                  >
                  {{ $fun.RMBNumSegmentation(goods.goodsPrice) }}￥
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      goodsList: [],
    };
  },
  computed: {
    exchangeRate() {
      return this.$store.state.exchangeRate;
    },
  },
  created() {
    // document.documentElement.scrollTop = document.body.scrollTop = 0;
    this.getRecommendedGoods();
  },
  methods: {
    getRecommendedGoods() {
      this.$api.getRecommendedGoods().then((res) => {
        res.data.forEach((item) => {
          item.index = 0;
          item.childs.sort(duiBi);
          function duiBi(a, b) {
            // time 是时间的字段 a-b 正序 b-a 倒序
            return Date.parse(b.sort) - Date.parse(a.sort);
          }
          item.resources = item.childs[0].resources;
          item.childs.forEach((items, indexs) => {
            items.index = indexs;
          });
        });
        this.goodsList = res.data;
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      });
    },

    getResources(menu, index, menus) {
      this.goodsList[index].resources = [];
      this.$forceUpdate();
      this.goodsList[index].resources = menu.resources;
      this.$forceUpdate();
      this.goodsList[index].index = menus;
    },
  },
};
</script>
<style lang="scss" scoped>
.classifyGoods {
  width: 1400px;
  margin: 0 auto;

  .classify {
    margin-bottom: 60px;

    .head {
      //   border-bottom: 2px solid #007ad0;
      display: flex;

      .title {
        flex: 1;
        height: 60px;
        font-size: 20px;
        color: #fff;
        line-height: 60px;
        text-align: center;
        border-radius: 6px;
        transform: translate(0, 1px);
        background-color: #351994;

        h3 {
          width: 300px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .menus {
        flex: 4;
        margin-left: 20px;
        margin-top: 10px;
        border-bottom: 2px solid #fff;
        display: flex;
        flex-wrap: nowrap;

        .menu {
          display: inline-block;
          margin-right: 20px;
          height: 40px;
          white-space: nowrap;
          cursor: pointer;
          position: relative;
          color: #2f2f2f;

          &:nth-last-child(1) {
            margin-right: 0px;
          }

          .menus-name {
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            border-radius: 4px;
            font-size: 15px;
          }

          .border {
            position: absolute;
            left: 0;
            bottom: -10px;
            height: 2px;
            width: 100%;
          }
        }

        .checked {
          .menus-name {
            font-weight: 600;
            color: #351994;
            background-color: #e3e0fb;
          }

          .border {
            background-color: #351994;
          }
        }
      }
    }

    .content {
      display: flex;
      margin-top: 20px;

      .bg {
        background-color: #fff;
        border-radius: 6px;
        width: 300px;
        height: 580px;
        border: 1px solid #e8e8e8;
        text-align: center;
        line-height: 580px;
        overflow: hidden;

        .el-img {
          width: 298px;
          height: 580px;
        }

        /deep/.el-image__inner {
          width: 300px;
          cursor: pointer;
          height: 580px !important;
        }

        i {
          font-size: 40px;
        }
      }

      .goodsList {
        flex: 4;
        padding-left: 20px;
        display: flex;
        flex-wrap: wrap;

        .item {
          border: 1px solid #e8e8e8;
          border-radius: 6px;
          width: 200px;
          margin-right: 20px;
          padding: 10px;
          background-color: #fff;
          margin-bottom: 20px;
          font-size: 14px;
          transition: 0.3s;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 0px 15px rgb(223, 223, 223);
          }

          &:nth-child(5n) {
            margin-right: 0;
          }

          &:nth-child(n + 6) {
            margin-bottom: 0;
          }

          .el-image {
            width: 178px;
            height: 178px;
            cursor: pointer;
            text-align: center;
            line-height: 178px;

            .el-icon-loading {
              font-size: 40px;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }

          .title {
            margin: 10px 0;
            line-height: 20px;
            color: #2f2f2f;

            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            font-size: 13px;
            font-weight: 500;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .price {
            color: #ffa202;
            height: 17px;
            line-height: 17px;
            font-size: 15px;
            font-weight: 500;
            overflow: hidden;

            span {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
}
.SearchConterCrosswise_public {
  flex: 4;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .SearchConterCrosswiseCon {
    $size: 200px;
    width: $size;
    margin: 0 0;

    .SearchConterCrosswiseImg {
      width: $size;
      height: $size;
      .el-image {
        width: $size;
        height: $size;
      }
    }
    .rests {
      padding: 10px 10px 15px;
    }
  }
}
</style>
