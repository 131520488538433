var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"RaUEbannerBox"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"goodsClass"},[_c('div',{staticClass:"allClass"},[_c('svg',{staticClass:"svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","id":"Capa_1","x":"0px","y":"0px","viewBox":"0 0 544 545.3","xml:space":"preserve"}},[_c('g',[_c('path',{attrs:{"d":"M218.3,245.9H58.6C43.9,245.9,32,234,32,219.3V59.7C32,45,43.9,33.1,58.6,33.1h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C244.9,234,232.9,245.9,218.3,245.9z M58.6,59.7v159.6h159.6V59.7L58.6,59.7z M58.6,46.4l0,13.3h0V46.4z"}})]),_c('g',[_c('path',{attrs:{"d":"M536.2,158.3L423.3,271.2c-10.4,10.4-27.3,10.4-37.6,0L272.8,158.3c-10.4-10.4-10.4-27.2,0-37.6L385.7,7.8  c10.4-10.4,27.3-10.4,37.6,0l112.9,112.9C546.6,131,546.6,147.9,536.2,158.3L536.2,158.3z M291.6,139.5l112.9,112.9l112.9-112.9  L404.5,26.6L291.6,139.5z M282.2,130.1l9.4,9.4l0,0L282.2,130.1z"}})]),_c('g',[_c('path',{attrs:{"d":"M484.3,512H324.7c-14.7,0-26.6-11.9-26.6-26.6V325.7c0-14.7,11.9-26.6,26.6-26.6h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C510.9,500.1,499,512,484.3,512z M324.7,325.7v159.6h159.6V325.7L324.7,325.7z M324.7,312.4l0,13.3h0V312.4z"}})]),_c('g',[_c('path',{attrs:{"d":"M218.3,512H58.6C43.9,512,32,500.1,32,485.4V325.7c0-14.7,11.9-26.6,26.6-26.6h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C244.9,500.1,232.9,512,218.3,512z M58.6,325.7v159.6h159.6V325.7L58.6,325.7z M58.6,312.4l0,13.3h0V312.4z"}})])]),_c('span',[_vm._v(_vm._s(_vm.$fanyi("特色类别")))])]),_c('div',{staticClass:"classMainBox"},_vm._l((_vm.lists),function(classItem,classIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(classIndex < 9),expression:"classIndex < 9"}],key:'class' + classIndex,staticClass:"goodsClassMain"},[_c('div',{staticClass:"goodsCl",on:{"click":function($event){_vm.$fun.routerToPage(
                '/CommoditySearch?keyword=' +
                  classItem.translation_name +
                  '&CN=' +
                  _vm.$fun.toCode(classItem.chinese_name)
              )}}},[_c('div',{staticClass:"classImg"},[_c('img',{attrs:{"src":classItem.default_icon,"alt":""}}),_c('img',{attrs:{"src":classItem.trigger_icon,"alt":""}})]),_c('div',{staticClass:"classSpan"},[_c('span',[_vm._v(_vm._s(!_vm.$store.state.showChinese ? classItem.translation_name : classItem.chinese_name))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"512","viewBox":"0 0 24 24","width":"512"}},[_c('g',{attrs:{"id":"_17","data-name":"17"}},[_c('path',{attrs:{"d":"m15 19a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1 -.7 1.71z"}})])])])]),_c('div',{staticClass:"classChildBox"},[_c('div',{staticClass:"classChild"},[_c('div',[_c('div',{staticClass:"className"},[_vm._v(" "+_vm._s(!_vm.$store.state.showChinese ? classItem.translation_name : classItem.chinese_name)+" ")]),_vm._l((classItem.childs),function(childsItem,childsIndex){return _c('a',{key:childsIndex,attrs:{"href":"#"},on:{"click":function($event){_vm.$fun.routerToPage(
                      '/CommoditySearch?keyword=' +
                        childsItem.translation_name +
                        '&CN=' +
                        _vm.$fun.toCode(childsItem.chinese_name)
                    )}}},[_vm._v(_vm._s(!_vm.$store.state.showChinese ? childsItem.translation_name : childsItem.chinese_name))])})],2)])])])}),0)]),_c('div',{staticClass:"banner"},[_c('el-carousel',{attrs:{"trigger":"click","height":"478px","interval":_vm.interval,"arrow":"never"}},_vm._l((_vm.bannerList),function(item,index){return _c('el-carousel-item',{directives:[{name:"show",rawName:"v-show",value:(item.status),expression:"item.status"}],key:index},[(item.href_url)?_c('a',{attrs:{"href":item.href_url}},[_c('img',{staticClass:"bannerImg",attrs:{"src":item.img_url,"alt":""}})]):_c('img',{staticClass:"bannerImg",attrs:{"src":item.img_url,"alt":""}})])}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }